$(function () {
    const myMSALObj = new Msal.UserAgentApplication(msalConfig);

    function signIn() {
        const loginRequest = {
            scopes: ["openid", "profile", "User.Read"]
        };
        return myMSALObj.loginPopup(loginRequest)
            .then(loginResponse => {
                return loginResponse;
            }).catch(error => {
            });
    }

    $(".login-azure").click(function () {
        signIn().then(res => {
            window.amplixsLogin.apicall('POST', 'loginAzure', JSON.stringify(res.idToken.rawIdToken))
                .then(function (result) {
                    window.amplixsLogin.showAuth(result, null);
                });
        });
        return false;
    })
});
