$(function () {
    google.accounts.id.initialize({
        client_id: googleConfig.client_id,
        callback: handleCredentialResponse
    });

    setTimeout(() => {
        $('.login-google').filter(':visible').each((key, elem) => {
            google.accounts.id.renderButton(
                elem,
                { size:"medium", logo_alignment: "center", text: "signin_with", locale: "en_US" }
            );
        });
    }, 5);

    function handleCredentialResponse(response) {
        login(response.credential);
    }

    function login(token) {
        window.amplixsLogin.apicall('POST', 'loginGoogle', JSON.stringify(token))
            .then(function (result) {
                window.amplixsLogin.showAuth(result, null);
            })
            .catch(() => {
                console.log('Unexpected error occurred connecting to Amplixs server.')
            });
    }

    $(".login-google").click(function () {
        return false;
    })
});
