$(function () {
    i18next
        .use(i18nextXHRBackend)
        .use(i18nextBrowserLanguageDetector)
        .init({
                load: 'languageOnly',
                detection: {
                    order: ['querystring', 'cookie', 'navigator'],
                    caches: ['cookie'],
                    lookupCookie: 'lang',
                    lookupQueryString: 'lang',
                    cookieMinutes: 31 * 24 * 60
                },
                fallbackLng: 'en',
                backend: {
                    loadPath: '/static/locales/{{lng}}.json'
                }
            },
            function (err, t) {
                jqueryI18next.init(i18next, $);
                var lang = i18next.language.split('-')[0];
                changeLanguage(lang);
            });

    $('.change-language').on('change', function () {
        changeLanguage($(this).val());
    })
});

function changeLanguage(lang) {
    if (lang) {
        i18next.changeLanguage(lang.toLowerCase(), function () {
            $('[data-i18n]').localize();
        });
    } else {
        $('[data-i18n]').localize();
    }

    changeSelectpickerLang(lang.toLowerCase());
    $('.change-language').val(lang.toUpperCase());
}

function changeSelectpickerLang(lang) {

    if(lang === 'nl') {

        $.fn.selectpicker.defaults = {
            noneSelectedText: 'Niets geselecteerd',
            noneResultsText: 'Geen resultaten gevonden voor {0}',
            countSelectedText: '{0} van {1} geselecteerd',
            maxOptionsText: ['Limiet bereikt ({n} {var} max)', 'Groep limiet bereikt ({n} {var} max)', ['items', 'item']],
            selectAllText: 'Alles selecteren',
            deselectAllText: 'Alles deselecteren',
            multipleSeparator: ', ',
            liveSearchPlaceholder: 'Typ hier om de lijst te filteren'
        };

    } else {

        $.fn.selectpicker.defaults = {
            noneSelectedText: 'Nothing selected',
            noneResultsText: 'No results match {0}',
            countSelectedText: function (numSelected, numTotal) {
                return (numSelected == 1) ? '{0} item selected' : '{0} items selected';
            },
            maxOptionsText: function (numAll, numGroup) {
                return [
                    (numAll == 1) ? 'Limit reached ({n} item max)' : 'Limit reached ({n} items max)',
                    (numGroup == 1) ? 'Group limit reached ({n} item max)' : 'Group limit reached ({n} items max)'
                ];
            },
            selectAllText: 'Select All',
            deselectAllText: 'Deselect All',
            multipleSeparator: ', ',
            liveSearchPlaceholder: 'Type here to filter the list'
        };

    }

    $('.selectpicker').each(function () {
        var id = $(this).attr('id');
        $('#' + id).selectpicker('destroy');
        $(this).selectpicker();
        $(this).addClass('selectpicker');
    })
}