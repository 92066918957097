$(function() {
  $('.form-group > .btn-group > select.selectpicker.form-control')
    .on('hide.bs.select', function (e)
    {
      $(this).parent().parent().removeClass('is-focused');
    })
    .on('show.bs.select', function (e)
    {
      $(this).parent().parent().addClass('is-focused');
    });
});