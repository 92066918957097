(function(window, $)
{
  var intent = '';
  var token = '';
  var state = null;
  var issuer = '';

  function getUrlParameter(parameterName) {
    var result = null,
    tmp = [];

    location.search
      .substr(1)
      .split("&")
      .forEach(function (item)
      {
        tmp = item.split("=");
        if (tmp[0] === parameterName)
          result = decodeURIComponent(tmp[1]);
      });

    return result;
  }

  function apiCall(method, func, args)
  {
    return $.ajax({
      'url': '/api/' + func,
      'method': method,
      'data': args,
      'dataType ': 'json',
      'headers': {
        'X-LOGIN-TOKEN': token,
        'X-LOGIN-ISSUER': issuer
      },
      xhrFields: {
        withCredentials: true
      }
    });
  }

  var api = {
    init: function(newIntent, newToken, newIssuer)
    {
      intent = newIntent;
      token = newToken;
      issuer = newIssuer;
      $('form').on('submit', function(event) {
        event.preventDefault();

        var frm = $(this);
        var method = frm.attr('method');
        var func = frm.attr('action');
        if (func === 'cancel' || func === 'retry')
        {
          if (window.parent === window)
            document.location.reload(true);
          else
            window.parent.postMessage({status: func}, "*");
          return;
        }

        var data = {};
        $.each(frm.serializeArray(), function(k, v) { data[v.name] = v.value; });

        frm.addClass('loading');
        frm.find('.btn').prop('disabled', true);

        apiCall(method, func, JSON.stringify(data))
          .then(function(result) { api.showAuth(result, func); })
          .always(function()
          {
            frm.removeClass('loading');
            frm.find('.btn').prop('disabled', false);
          });
      })
    },

    apicall: function(method, func, args) {
      return apiCall(method, func, args);
    },

    showAuth: function(newState, func)
    {
      state = newState;

      $('section').hide();
      $('button[form=cancel]').toggle(intent !== 'showAuth');

      if ((intent === 'start' || intent === 'reset') && state.status !== intent)
      {
        document.location = '/auth';
        return;
      }

      $(".toggle-password").click(function () {
        $('.toggle-password').toggleClass("fa-eye fa-eye-slash");
        var input = $('.toggle-password-field');
        if (input.attr("type") === "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });

      var section;
      var error;
      if (state.status === 'test')
      {
        section = $('#section-test');
      }
      else if (state.status === 'signup')
      {
        section = $('#section-signup');
      }
      else if (state.status === 'signup-email')
      {
        section = $('#section-signup-email');
      }
      else if (state.status === 'configure_u2f')
      {
        section = $('#section-configure-u2f');

        $('button').on('click', function () {

          var type = $(this).data('type');

          $("<input />").attr("type", "hidden")
              .attr("name", "u2f_type")
              .attr("value", type)
              .appendTo("form");
           });

      }
      else if (state.status === 'configure_u2f_totp')
      {
        section = $('#section-configure-u2f-totp');
        var qrcode = new QRCode("u2f_totp_qrcode", {
          text: state.appUrl,
          width: 250,
          height: 250,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        });
      }
      else if (state.status === 'configure_u2f_validate')
      {

        section = $('#section-configure-u2f-validate');

        if($().pinlogin)
        {
          $("#configure_token").attr('type', 'hidden');

          $("#configure_token_pin_input").pinlogin({
            fields: 6,
            hideinput: false,
            reset: true,
            complete : function(pin){
              $("#configure_token").val(pin);
              $('#check_token').trigger('click');
            }
          }).focus(0);
        }
        else
        {
          $("#configure_token").attr('type', 'text');
        }

      }
      else if (state.status === 'configure_password')
      {
        section = $('#section-signup-password');
        error = "Unable to change password: <ul><li>" + state.errors.join("</li><li>") + "</li></ul>";
        $(section).find('.form-invalid-feedback').html(error);
        $('#email_signup_password').val(state.email);
      }
      else if (state.status === 'password_expired')
      {
        section = $('#section-password-expired');
        error = "Unable to change password: <ul><li>" + state.errors.join("</li><li>") + "</li></ul>";
        $(section).find('.form-invalid-feedback').html(error);
        $('#email_password_expired').val(state.email);
      }
      else if (state.status === 'forgot_password')
      {
        section = $('#section-forgot-password');
      }
      else if (state.status === 'forgot-password-email')
      {
        section = $('#section-forgot-password-email');
      }
      else if (state.status === 'reset')
      {
        $('#reset_token').val(getUrlParameter('token'));
        section = $('#section-forgot-password-reset');
      }
      else if (state.status === 'start')
      {
        $('#start_token').val(getUrlParameter('token'));
        section = $('#section-signup-start');
      }
      else if (state.status === 'other_app')
      {
        if (window.parent === window)
          window.location = state.appUrl;
        else
          window.parent.postMessage(state, "*");
        return;
      }
      else if (state.status === 'not_authenticated')
      {
        section = $('#section-login');
      }
      else if (state.status === 'login_expired')
      {
        section = $('#section-relogin');
        $('#email_relogin').val(state.email);
      }
      else if (state.status === 'locked')
      {
        section = $('#section-unlock');

        if($().pinlogin)
        {
           $("#unlock_token").attr('type', 'hidden');

           $("#unlock_token_pin_input").pinlogin({
              fields: 6,
              hideinput: false,
              reset: true,
              complete : function(pin){
                $("#unlock_token").val(pin);
                $('#submit_token').trigger('click');
              }
          }).focus(0);
        }
        else
        {
          $("#unlock_token").attr('type', 'text');
        }

      }
      else if (state.status === 'switch_user' || (intent === 'switchUser' && !func && state.status === 'authenticated' && state.options.possibleUsers.length > 1))
      {
        section = $('#section-switch-user');
        var userSelect = $('#user');
        userSelect.empty();
        var first = null;
        var selected = null;
        $.each(state.options.possibleUsers, function(key, user)
        {
          if (!first)
            first = user.user;
          if (user.selected)
            selected = user.user;

          userSelect
            .append($("<option></option>")
            .attr("value", user.user)
            .attr('data-subtext', user.email)
            .text(user.name));
        });

        userSelect.selectpicker('refresh');
        userSelect.selectpicker('val', selected ? selected : first);
        userSelect.selectpicker('render');
      }
      else if (state.status === 'switch_app' || (intent === 'switchApp' && !func && state.status === 'authenticated' && state.options.possibleApps.length > 1))
      {
        section = $('#section-switch-app');
        var appSelect = $('#app');
        appSelect.empty();
        var firstApp = null;
        var selectedApp  = null;
        $.each(state.options.possibleApps, function(key, app)
        {
          if (!firstApp)
            firstApp = app.id;
          if (app.selected)
            selectedApp = app.id;

          var name = app.display_name ? app.display_name : (app.vhost + " -- " + app.app);
          appSelect
            .append($("<option></option>")
              .attr("value", app.id)
              .text(name));
        });

        appSelect.selectpicker('refresh');
        appSelect.selectpicker('val', selectedApp ? selectedApp : firstApp);
        appSelect.selectpicker('render');
      }
      else if (state.status === 'no_access')
      {
        section = $('#section-no-access');
      }
      else if (state.status === 'authenticated')
      {
          if (window.parent === window) {
               window.location.reload(true);
          } else {
              window.parent.postMessage(state, "*");
          }
        return;
      }
      else
      {
        section = $('#section-error');

        error = "State:" + state.status + "<br /><ul><li>" + state.errors.join("</li><li>") + "</li></ul>";
        $('#errors').html(error);
      }

      var card = section.find('.card');
      var form = card.find('form');

      section.show();
      form.toggleClass('is-invalid', state.hasErrors);
      if (state.hasErrors)
      {
        card.on('animationend', function() { card.css('animation', ''); });
        card.css('animation', 'shake 0.5s');
      }

      form.find('input, textarea, select').first().focus();
    }
  };

  window.amplixsLogin = api;

})(window, jQuery || $);
